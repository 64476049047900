






import Vue from "vue"
import Component from "vue-class-component"
import { Watch } from "vue-property-decorator"

export default class MemberManager extends Vue {
}
